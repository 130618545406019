//(function (root, factory) {
//  if (typeof define === 'function' && define.amd) {
//    // AMD. Register as an anonymous module.
//    define([], function () {
//      return root.returnExportsGlobal = factory()
//    })
//  } else if (typeof exports === 'object') {
//    // Node. Does not work with strict CommonJS, but
//    // only CommonJS-like enviroments that support module.exports,
//    // like Node.
//    module.exports = factory()
//  } else {
//    root['Chartist.plugins.ctPresControls'] = factory()
//  }
//}(this, function () {
/**
	 * Chartist.js plugin that generates controls to change presentation of charts for better accessibility.
	 *
	 */
/* global Chartist */
(function (window, document, Chartist) {
  'use strict';
  var defaultOptions = {
    egsb_colors: 'null',
    // hex, oct
    egsb_toggle_class: '',
    // ftb-chart__toggles--s
    egsb_inverter_toggle: 'no',
    // yes, no
    egsb_pattern_toggle: 'no',
    // yes, no
    egsb_legend_toggle: 'no',
    // yes, no
    egsb_table_toggle: 'no',
    // yes, no
    egsb_inverter: 'off',
    // on, off
    egsb_pattern: 'off',
    // on, off
    egsb_legend: 'off',
    // on, off
    egsb_pattern_bg: '#505050',
    elementId: function () {
      return 'ftb-chart__toggles-' + +new Date()
    }
  };
  Chartist.plugins = Chartist.plugins || {};
  Chartist.plugins.ctPresControls = function (options) {
    options = Chartist.extend({}, defaultOptions, options);
    return function ctPresControls(chart) {
      var elementId = typeof options.elementId === 'function' ? options.elementId() : options.elementId;
      chart.on('created', function (data) {
        // get the chart element
        var chartElement = data.svg._node.parentNode;
        // get the containing figure element
        var containerElement = chartElement.parentNode;
        var previousElement = containerElement.querySelector('#' + elementId);
        if (previousElement) {
          containerElement.removeChild(previousElement)
        }
        // 
        // base configuration
        // 
        // add patterns to charts svg, if the chart is a pie
        if (chartElement.__chartist__ instanceof Chartist.Pie & chartElement.__chartist__.options.donut === false) {
          data.svg._node.appendChild(textures.lines().id('0').background(options.egsb_pattern_bg).heavier().thicker().getNode());
          data.svg._node.appendChild(textures.circles().id('1').background(options.egsb_pattern_bg).radius(5).size(15).complement().getNode());
          data.svg._node.appendChild(textures.lines().id('2').background(options.egsb_pattern_bg).orientation('1/8').getNode());
          data.svg._node.appendChild(textures.lines().id('3').background(options.egsb_pattern_bg).orientation('3/8', '7/8').getNode());
          data.svg._node.appendChild(textures.circles().id('4').background(options.egsb_pattern_bg).thicker().complement().getNode());
          data.svg._node.appendChild(textures.paths().id('5').d('waves').background(options.egsb_pattern_bg).getNode());
          data.svg._node.appendChild(textures.lines().id('6').background(options.egsb_pattern_bg).heavier(10).thinner(1.5).getNode());
          data.svg._node.appendChild(textures.paths().id('7').d('squares').size(8).strokeWidth(2).background(options.egsb_pattern_bg).getNode());
          data.svg._node.appendChild(textures.circles().id('8').background(options.egsb_pattern_bg).radius(3).size(15).complement().getNode());
          // in case of pie charts we need a solid background for the textual labels
          // to prevent contrast issues
          var xmlns = 'http://www.w3.org/2000/svg';
          var textBgFilterDef = document.createElementNS(xmlns, 'defs');
          var textBgFilter = document.createElementNS(xmlns, 'filter');
          textBgFilter.setAttributeNS(null, 'id', 'solid');
          textBgFilter.setAttributeNS(null, 'x', '0');
          textBgFilter.setAttributeNS(null, 'y', '0');
          textBgFilter.setAttributeNS(null, 'width', '105%');
          textBgFilter.setAttributeNS(null, 'height', '1');
          var textBgFlood = document.createElementNS(xmlns, 'feFlood');
          textBgFlood.setAttributeNS(null, 'class', 'ct-label-bg-color');
          var textBgComposite = document.createElementNS(xmlns, 'feComposite');
          textBgComposite.setAttributeNS(null, 'in', 'SourceGraphic');
          textBgFilter.appendChild(textBgFlood);
          textBgFilter.appendChild(textBgComposite);
          textBgFilterDef.appendChild(textBgFilter);
          data.svg._node.appendChild(textBgFilterDef)
        }
        //        // initial color setting
        //        if (options.egsb_colors == 'hex') {
        //          containerElement.classList.add('ftb-chart--hex')
        //        } else if (options.egsb_colors == 'oct') {
        //          containerElement.classList.add('ftb-chart--oct')
        //        } else if (options.egsb_colors == null) {
        //          containerElement.classList.add('ftb-chart--oct')
        //        }
        //default: no change of color setting 
        // initial inverter setting
        if (options.egsb_inverter == 'on') {
          containerElement.classList.add('ftb-chart--inv')
        } else if (options.egsb_inverter == 'off') {
          containerElement.classList.remove('ftb-chart--inv')
        }
        //default: no change of the setting specified by the class name	  
        // initial pattern setting
        if (options.egsb_pattern == 'on') {
          containerElement.classList.add('ftb-chart--pattern');
          // work-around for firefox, which supports only inline-styles 
          // the nodeList returned by querySelectorAll is in reversed order,
          // so another work-around using a counter is applied
          if (chartElement.__chartist__ instanceof Chartist.Pie & chartElement.__chartist__.options.donut === false) {
            var chartAreas = chartElement.querySelectorAll('.ct-series');
            var counter = chartAreas.length;
            for (var int = 0; int < chartAreas.length; int += 1) {
              chartAreas[int].setAttributeNS(null, 'style', 'mask: url(#mask-' + (counter -= 1) + ')')
            }
            // if a legend is applied, changes have to be done also there
            if (Chartist.plugins.ctLegend) {
              chartAreas = containerElement.querySelectorAll('.ftb-chart__legend .ct-area');
              for (int = 0; int < chartAreas.length; int += 1) {
                chartAreas[int].setAttributeNS(null, 'style', 'mask: url(#mask-' + int + ')')
              }
            }
          }
        } else if (options.egsb_pattern == 'off') {
          containerElement.classList.remove('ftb-chart--pattern');
          // work-around for firefox, which supports only inline-styles 
          if (chartElement.__chartist__ instanceof Chartist.Pie & chartElement.__chartist__.options.donut === false) {
            chartAreas = chartElement.querySelectorAll('.ct-series');
            for (int = 0; int < chartAreas.length; int += 1) {
              chartAreas[int].removeAttribute('style')
            }
            // if a legend is applied, changes have to be done also there
            if (Chartist.plugins.ctLegend) {
              chartAreas = containerElement.querySelectorAll('.ftb-chart__legend .ct-area');
              for (int = 0; int < chartAreas.length; int += 1) {
                chartAreas[int].removeAttribute('style')
              }
            }
          }
        }
        //default: no change of the setting specified by the class name	
        //
        // create controls
        //
        // create wrapper element containing control buttons,
        var btnWrapper = document.createElement('div');
        var button;
        btnWrapper.setAttribute('id', elementId);
        btnWrapper.setAttribute('class', 'ftb-chart__toggles');
        btnWrapper.setAttribute('aria-hidden', 'true');
        if (options.egsb_toggle_class.length > 0) {
          btnWrapper.classList.add(options.egsb_toggle_class)
        }
        if (options.egsb_pattern_toggle == 'yes') {
          btnWrapper.appendChild(getToggleButton(containerElement, chartElement, 'ftb-chart--pattern', 'Muster:'))
        }
        if (options.egsb_inverter_toggle == 'yes') {
          btnWrapper.appendChild(getToggleButton(containerElement, chartElement, 'ftb-chart--inv', 'Invertieren:'))
        }
        if (options.egsb_legend_toggle == 'yes' || options.egsb_legend_toggle == 'off') {
          if (button = getPluginToggleButton(containerElement, 'ftb-chart__legend', 'ftb-chart__legend--display_none', 'Legende:', options.egsb_legend_toggle)) {
            btnWrapper.appendChild(button)
          }
        }
        if (options.egsb_table_toggle == 'yes' || options.egsb_table_toggle == 'off') {
          if (button = getPluginToggleButton(containerElement, 'ftb-chart__table', 'ym-hideme', 'Tabelle:', options.egsb_table_toggle)) {
            btnWrapper.appendChild(button)
          }
        }
        // insert the buttons directly below the chart element
        containerElement.insertBefore(btnWrapper, containerElement.querySelector('.ct-chart').nextSibling)
      })
    }
  };
  /*
       * helper function to generate button elements
       * 
       * @param {node} containerElement: the element holding the chart
       * @param {string} cssClassName: class name used to toggle visual customizations
       * @param {string} btnText: text to be displayed as a label
       * @return {node} the button with click listener attached
       */
  function getPluginToggleButton(containerElement, elementClass, cssClassName, btnText, toggleStatus) {
    // create controls to toggle patterns on lines and areas
    var texte = {
      'true': 'einschalten',
      'false': 'ausschalten'
    };
    var element = containerElement.querySelector('.' + elementClass);
    if (element) {
      if (toggleStatus == 'off') {
        element.classList.add(cssClassName)
      }
      var status = !element.classList.contains(cssClassName);
      var btn = document.createElement('button');
      //      btn.setAttribute('tabindex', '0');
      //      // hide button from screenreaders
      //      btn.setAttribute('aria-hidden', 'true')
      btn.setAttribute('class', 'ftb-chart__toggle' + (status ? ' ftb-chart__toggle--checked' : ''));
      btn.setAttribute('role', 'checkbox');
      btn.setAttribute('aria-checked', status);
      btn.setAttribute('aria-controls', element);
      btn.innerHTML = btnText + ' <span class="ym-hideme">' + texte[status] + '</span>';
      btn.addEventListener('click', function (event) {
        var source = event.target || event.srcElement;
        status = !status;
        source.setAttribute('class', 'ftb-chart__toggle' + (status ? ' ftb-chart__toggle--checked' : ''));
        source.setAttribute('aria-checked', status);
        source.innerHTML = btnText + ' <span class="ym-hideme">' + texte[status] + '</span>';
        if (status) {
          element.classList.remove(cssClassName)
        } else {
          element.classList.add(cssClassName)
        }
      })
    }
    return btn
  }
  /*
		 * helper function to generate button elements
		 * 
		 * @param {node} containerElement: the element holding the chart
		 * @param {string} cssClassName: class name used to toggle visual customizations
		 * @param {string} btnText: text to be displayed as a label
		 * @return {node} the button with click listener attached
		 */
  function getToggleButton(containerElement, chartElement, cssClassName, btnText) {
    // create controls to toggle patterns on lines and areas
    var texte = {
      'false': 'einschalten',
      'true': 'ausschalten'
    };
    var status = containerElement.classList.contains(cssClassName);
    //			var aria_by = containerElement.getAttribute("aria-describedby");
    //			var legend = aria_by ? document.getElementById(aria_by) : null;
    var btn = document.createElement('button');
    //    btn.setAttribute('tabindex', '0');
    //    // hide button from screenreaders
    //    btn.setAttribute('aria-hidden', 'true');
    btn.setAttribute('class', 'ftb-chart__toggle' + (status ? ' ftb-chart__toggle--checked' : ''));
    btn.setAttribute('role', 'checkbox');
    btn.setAttribute('aria-checked', status);
    btn.setAttribute('aria-controls', containerElement);
    btn.innerHTML = btnText + ' <span class="ym-hideme">' + texte[status] + '</span>';
    btn.addEventListener('click', function (event) {
      var source = event.target || event.srcElement;
      status = !status;
      source.setAttribute('class', 'ftb-chart__toggle' + (status ? ' ftb-chart__toggle--checked' : ''));
      source.setAttribute('aria-checked', status);
      source.innerHTML = btnText + ' <span class="ym-hideme">' + texte[status] + '</span>';
      if (status) {
        containerElement.classList.add(cssClassName);
        // work-around for firefox, which supports only inline-styles 
        // the nodeList returned by querySelectorAll is in reversed order,
        // so another work-around is applied
        if (cssClassName === 'ftb-chart--pattern' && chartElement.__chartist__ instanceof Chartist.Pie & chartElement.__chartist__.options.donut === false) {
          var chartAreas = chartElement.querySelectorAll('.ct-series');
          var counter = chartAreas.length;
          for (var int = 0; int < chartAreas.length; int += 1) {
            chartAreas[int].setAttributeNS(null, 'style', 'mask: url(#mask-' + (counter -= 1) + ')')
          }
          // if a legend is applied, changes have to be done also there
          if (Chartist.plugins.ctLegend) {
            chartAreas = containerElement.querySelectorAll('.ftb-chart__legend .ct-area');
            for (int = 0; int < chartAreas.length; int += 1) {
              chartAreas[int].setAttributeNS(null, 'style', 'mask: url(#mask-' + int + ')')
            }
          }
        }
      } else {
        containerElement.classList.remove(cssClassName);
        // work-around for firefox, which supports only inline-styles 
        if (cssClassName === 'ftb-chart--pattern' && chartElement.__chartist__ instanceof Chartist.Pie & chartElement.__chartist__.options.donut === false) {
          chartAreas = chartElement.querySelectorAll('.ct-series');
          for (int = 0; int < chartAreas.length; int += 1) {
            chartAreas[int].removeAttribute('style')
          }
          // if a legend is applied, changes have to be done also there
          if (Chartist.plugins.ctLegend) {
            chartAreas = containerElement.querySelectorAll('.ftb-chart__legend .ct-area');
            for (int = 0; int < chartAreas.length; int += 1) {
              chartAreas[int].removeAttribute('style')
            }
          }
        }
      }
    });
    return btn
  }
}(window, document, Chartist))  //  return Chartist.plugins.ctPresControls
                                //}))
